.modal {
  &-title {
    p {
      font-size: 18px;
    }

    hr {
      margin: 15px 0 25px 0;
    }
  }
}
