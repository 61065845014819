@import '~@angular/material/theming';
@include mat-core();

$trust-pr-ui-primary: mat-palette($mat-green, 800);
$trust-pr-ui-accent: mat-palette($mat-orange, 800);
$trust-pr-ui-warn: mat-palette($mat-red);

$akropoli-pr-ui-theme: mat-light-theme($trust-pr-ui-primary, $trust-pr-ui-accent, $trust-pr-ui-warn);

@include angular-material-theme($akropoli-pr-ui-theme);

$primary: #007d58;
$secondary: #e4a025;
$warn: red;
$orange: #ff9800;

